<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper p-0">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                Knowledge Base
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <b-dropdown
              v-if="user.roles.includes('super-admin')"
              variant="link"
              no-caret
              toggle-class="p-0"
              right
            >
              <template #button-content>
                <button
                  id="dropdownMenuButton600"
                  class="btn btn-icon btn-primary"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i data-feather="plus" />
                </button>
              </template>
              <div
                class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
              >
                <b-dropdown-item @click="openAddKnowledgeBaseSection()">
                  <i
                    class="me-50"
                    data-feather="plus"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Add Content in knowledge base"
                  /> Section
                </b-dropdown-item>
                <div class="dropdown-divider" />
                <b-dropdown-item @click="$router.push({name: 'knowledge-base.create'})">
                  <i
                    class="me-50"
                    data-feather="plus"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Add Content in knowledge base"
                  /> Chapter
                </b-dropdown-item>
              </div>
            </b-dropdown>
            <a
              class="btn btn-icon btn-primary d-block d-sm-none"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasMenu"
              aria-controls="offcanvasBottom"
            ><i
              data-feather="plus"
            /></a>
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Professional background settings"
              @click="openSettingsKnowledgeBaseSection()"
            ><i data-feather="menu" /></a>
          </div>
        </div>
      </div>
      <div class="content-body">
        <!-- Knowledge base Jumbotron -->
        <section id="knowledge-base-search">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-9 knowledge-base-search--form">
                  <h2 class="text-primary">
                    Dedicated Source Used on Website
                  </h2>
                  <form class="kb-search-input">
                    <div class="input-group input-group-merge">
                      <span class="input-group-text">
                        <i data-feather="search" />
                      </span>
                      <v-select
                        v-model="selectedChapterSearch"
                        placeholder="Ask a question"
                        label="title"
                        class="w-90"
                        :options="filteredChapters"
                        :get-option-key="option => option.id"
                        :filter="fixFilter"
                        @input="redirectToShow"
                        @search="onSearch"
                      />
                    </div>
                  </form>
                </div>
                <div class="col-sm-3">
                  <img
                    :src="require('@/assets/images/illustration/training-card.svg')"
                    alt="Training"
                    class="img-fluid"
                  >
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--/ Knowledge base Jumbotron -->

        <!-- Knowledge base category Content  -->
        <section id="knowledge-base-category">
          <div class="row kb-search-content-info match-height">
            <KnowledgeBaseSection
              v-for="(item, index) in sections"
              :key="index"
              :item="item"
            />
          </div>
          <div
            v-if="loading"
            class="col-12 text-center no-result no-items"
          >
            <div class="loading">
              <div class="effect-1 effects" />
              <div class="effect-2 effects" />
              <div class="effect-3 effects" />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="card">
                <div class="card-body">
                  <h2>Can't find the help you need?</h2>
                  <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo obcaecati doloribus, accusamus architecto laboriosam distinctio dolorum aspernatur nisi ducimus deserunt doloremque cupiditate excepturi ea at veritatis quasi, minima quae iste.</p>
                  <p><a
                    href="../contact/index.php"
                    class="btn btn-secondary btn-sm"
                  >Contact us</a></p>
                  <picture>
                    <img
                      :src="require('@/assets/images/illustration/contact-card.svg')"
                      class="img-fluid"
                      alt="Contact us"
                    >
                  </picture>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="card">
                <div class="card-body">
                  <h2>Training space</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil tempore unde asperiores, incidunt officia cum enim et laborum quaerat, adipisci beatae itaque amet voluptates quod aperiam iste corporis obcaecati recusandae!</p>
                  <p><a
                    href="../training/index.php"
                    class="btn btn-secondary btn-sm"
                  >Access</a></p>
                  <picture>
                    <img
                      :src="require('@/assets/images/illustration/training-card.svg')"
                      class="img-fluid"
                      alt="Training"
                    >
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--/ Knowledge base category Content -->

      </div>
    </div>
    <ItemInfoSheet>
      <template #info-sheet-title>
        <template v-if="selectedAction == 'newSection'"> <!-- /* SHOWING SECTION */ -->
          Sections
        </template>
        <template v-if="selectedAction == 'settings'"> <!-- /* SHOWING SETTINGS */ -->
          Settings
        </template>
      </template>
      <template #info-sheet>
        <template v-if="selectedAction == 'newSection'"> <!-- /* SHOWING SECTION */ -->
          <div class="offcanvas-body">
            <h6>Add a new section or edit</h6>
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >Title new section</label>
              <input
                v-model="section.title"
                type="text"
                class="form-control"
              >
            </div>
            <div class="mb-1">
              <button
                class="btn btn-sm btn-primary"
                @click="saveSection"
              >
                Add
              </button>
            </div>
            <hr>
            <ul
              id="basic-list-group"
              class="list-group"
            >
              <li
                v-for="(item, index) in allSections"
                :key="index"
                class="list-group-item draggable p-1"
              >
                <div
                  v-if="!isEditing(item)"
                  class="d-flex"
                >
                  <span class="icon-drag me-1"><i data-feather="menu" /></span>
                  <div class="more-info">
                    {{ item.title }}
                    <span
                      v-if="item.active == true"
                      class="badge bg-light-success mx-75"
                    >active</span>
                    <span
                      v-if="item.active == false"
                      class="badge bg-light-danger mx-75"
                    >inactive</span>
                  </div>
                  <a
                    class="btn btn-icon rounded-circle btn-flat-secondary pt-1 mx-225"
                    @click="selectedSectionsToEdit(index, item, 'edit')"
                  ><i data-feather="edit-2" /></a>
                  <a
                    class="btn btn-icon rounded-circle btn-flat-secondary pt-1"
                    @click="showDeleteModal(item.id, 'section')"
                  ><i data-feather="trash-2" /></a>
                </div>
                <div
                  v-else
                  class="d-flex"
                >
                  <div>
                    <input
                      v-model="item.title"
                      type="text"
                      class="form-control h-100"
                    >
                  </div>
                  <div class="more-info mt-50">
                    <input
                      id="tableColumn1"
                      v-model="item.active"
                      type="checkbox"
                      class="form-check-input"
                    >
                  </div>
                  <a
                    class="btn btn-icon rounded-circle btn-flat-secondary waves-effect mt-75"
                    @click="selectedSectionsToEdit(index, item, 'cancel')"
                  >x</a>
                </div>
              </li>
            </ul>
            <div class="offcanvas-footer mt-auto">
              <button
                v-if="editingSection"
                type="button"
                class="btn btn-success mb-1 d-grid w-100"
                @click="updateSection"
              >
                Apply changes
              </button>
            </div>
          </div>
        </template>
        <template v-if="selectedAction == 'settings'"> <!-- /* SHOWING SETTINGS */ -->
          <div class="offcanvas-body offcanvas-body--view">
            <h6>Reorder sections and chapters</h6>
            <ul
              id="basic-list-group"
              class="list-group"
            >
              <draggable
                v-model="realSections"
                group="people"
                @start="drag=true"
                @end="drag=false"
              >

                <li
                  v-for="(sec, indexSec) in realSections"
                  :key="indexSec"
                  v-b-toggle="`collapseChapters-${sec.id}`"
                  class="list-group-item draggable"
                >
                  <div class="d-flex">
                    <span class="icon-drag"><i data-feather="menu" /></span>
                    <div class="more-info">
                      <div class="form-check">
                        <label
                          class="form-check-label"
                          for="tableColumn1"
                        >{{ sec.title }}</label>
                      </div>
                    </div>
                    <a
                      data-action="collapse"
                      class="btn btn-icon rounded-circle btn-flat-secondary waves-effect"
                    ><i data-feather="chevron-down" /></a>
                  </div>
                  <b-collapse
                    :id="`collapseChapters-${sec.id}`"
                    class="mt-1"
                  >
                    <ul
                      id="basic-list-group"
                      class="list-group"
                    >
                      <draggable
                        v-model="realSections[indexSec].chapters"
                        group="people"
                        @start="drag=true"
                        @end="drag=false"
                      >
                        <li
                          v-for="(chap, indexChap) in realSections[indexSec].chapters"
                          :key="indexChap"
                          class="list-group-item draggable"
                        >
                          <div class="d-flex">
                            <span class="icon-drag"><i data-feather="menu" /></span>
                            <div class="more-info">
                              <div class="form-check">
                                <label
                                  class="form-check-label"
                                  for="tableColumn1"
                                >{{ chap.title }}</label>
                              </div>
                            </div>
                          </div>
                        </li>
                      </draggable>
                    </ul>
                  </b-collapse>
                </li>
              </draggable>
            </ul>
          </div>
          <div class="offcanvas-footer mt-auto">
            <button
              type="button"
              class="btn btn-success mb-1 d-grid w-100"
              @click="applyChanges"
            >
              Apply changes
            </button>
          </div>
        </template>
      </template>
    </ItemInfoSheet>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { BDropdown, BDropdownItem, BCollapse } from 'bootstrap-vue'
import Draggable from 'vuedraggable'
import KnowledgeBaseSection from './components/KnowledgeBaseSection.vue'
import ItemInfoSheet from '../../partials/offcanvas/ItemInfoSheet.vue'

export default {
  components: {
    KnowledgeBaseSection,
    ItemInfoSheet,
    BDropdown,
    BDropdownItem,
    Draggable,
    BCollapse,
    vSelect,
  },
  data() {
    return {
      selectedAction: '',
      section: {
        title: undefined,
        active: true,
      },
      editingSection: false,
      selectedSections: [],
      realSections: [],
      loading: false,
      selectedChapterSearch: null,
    }
  },
  computed: {
    ...mapGetters({
      // fields: 'modals/tableSettingsFields',
      sections: 'knowledgeBase/sections',
      allSections: 'knowledgeBase/allSections',
      searchedWords: 'knowledgeBase/searchedWords',
      filteredChapters: 'knowledgeBase/filteredChapters',
      user: 'auth/admin',
      // patentsTotal: 'patentStatus/patentsTotal',
    }),
  },
  watch: {
    sections() {
      // this.realSections = Object.keys(this.allSections).map(key => this.allSections[key])
      this.realSections = Object.keys(this.sections).map(key => this.sections[key])
    },
  },
  async mounted() {
    // await this.$store.dispatch('modals/fetchUserFields', 'knowledge-base-section')
    this.loading = true
    await this.$store.dispatch('knowledgeBase/filter', this.$store.getters['filters/filters'])
    this.loading = false
    await this.$store.dispatch('knowledgeBase/fetchAllSections')
    await this.$store.dispatch('knowledgeBase/cleanSearchedWords')
    // this.realSections = Object.keys(this.allSections).map(key => this.allSections[key])
    this.realSections = Object.keys(this.sections).map(key => this.sections[key])
  },
  methods: {
    openAddKnowledgeBaseSection() {
      this.selectedAction = 'newSection'
      this.$store.dispatch('modals/toggleInfoItemSheet', true)

      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    openSettingsKnowledgeBaseSection() {
      this.selectedAction = 'settings'
      this.$store.dispatch('modals/toggleInfoItemSheet', true)

      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    async saveSection() {
      await this.$store.dispatch('knowledgeBase/create', this.section)
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
      this.section.title = undefined
      this.realSections = Object.keys(this.sections).map(key => this.sections[key])
    },
    selectedSectionsToEdit(key, value, method) {
      if (method === 'edit') {
        this.editingSection = true
        this.selectedSections.push(value)
      } else if (method === 'delete') {
        //
      } else if (method === 'cancel') {
        this.selectedSections.splice(this.selectedSections.indexOf(value), 1)
        this.editingSection = this.selectedSections.length > 0
      }

      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    isEditing(index) {
      return this.selectedSections.indexOf(index) > -1
    },
    async updateSection() {
      await this.$store.dispatch('knowledgeBase/update', {
        selectedSections: this.selectedSections,
      })
      await this.$store.dispatch('knowledgeBase/filter', this.$store.getters['filters/filters'])
      this.$store.dispatch('modals/toggleInfoItemSheet', false)
    },
    closeOffcanva() {
      this.$store.dispatch('modals/toggleInfoItemSheet', false)
    },
    async applyChanges() {
      await this.$store.dispatch('knowledgeBase/reorder', {
        sections: this.realSections,
      })
      this.closeOffcanva()
      await this.$store.dispatch('knowledgeBase/fetchAllSections')
    },
    async onSearch(search) {
      if (search.length >= 3) {
        await this.$store.dispatch('knowledgeBase/filterChapters', {
          searchedWords: search,
        })
        await this.$store.dispatch('knowledgeBase/saveSearchedWords', search)
      }
    },
    fixFilter(value, search) {
      if (!value || value.length < 1 || search.length < 3) {
        return false
      }
      return value.filter(item => item.content.includes(search))
    },
    redirectToShow() {
      if (this.selectedChapterSearch) {
        this.$router.push({ name: 'knowledge-base.show', params: { id: this.selectedChapterSearch.id } })
      }
    },
    async showDeleteModal(id, anotation) {
      if (anotation === 'chapter') {
        await this.$store.dispatch('modals/saveDeleteData', { name: 'knowledge-base.index', url: `${Vue.prototype.$groupUrl}/knowledge-base-chapters/${id}`, requiredFetch: false })
        this.$store.dispatch('modals/showDeleteModal', true)
      }

      if (anotation === 'section') {
        await this.$store.dispatch('modals/saveDeleteData', { name: ['knowledgeBase/fetchAllSections', 'knowledgeBase/fetch'], url: `${Vue.prototype.$groupUrl}/knowledge-base-sections/${id}` })
        await this.$store.dispatch('modals/showDeleteModal', true)
      }
    },
  },
}
</script>

<style>

</style>
